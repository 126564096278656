import React from "react";
import { BrowserRouter, Route, Routes as Switch } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import SignUp from "./pages/SignUp";
import Admin from "./pages/Dashboard/Admin";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import PlaceList from "./components/PlaceList";
import ToursList from "./components/ToursList";
import ImageGallery from "./components/ImageGallery";
import Tours from "./pages/Tours";

export default function Routes() {
  const isAuthenticated = useSelector(
    (state) => state.reducer.auth.isAuthenticated
  );
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/gallery" element={<Gallery />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/tours" element={<Tours />} />
        <Route exact path="/tours/:location" element={<ToursList />} />
        <Route path="/:location" element={<PlaceList />} />
        <Route path="/:location/:place" element={<ImageGallery />} />
        {isAuthenticated ? (
          <Route path="/admin" element={<Admin />} />
        ) : (
          <>
            <Route path="*" element={<Navigate to="/signup" />} />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
}
