import Utils from "../utils";
import { control } from "../control";
import styled from "styled-components";
import about from "../assets/about.jpg";
import React, { useContext } from "react";
import { ScreenWidthContext } from "../providers/ScreenWidth";

const CheckMark = ({ title }) => {
  return (
    <div className="d-flex align-items-center mb-2 gap-2">
      <IconWrapper className="d-flex justify-content-center align-items-center rounded-circle text-white bp">
        <i className="bi bi-check2" />
      </IconWrapper>
      <span className="font-primary-regular">{title}</span>
    </div>
  );
};

export default function About() {
  const width = useContext(ScreenWidthContext);
  return (
    <section className="container pt-5">
      <div className="row">
        <div className="col-12 col-xl-6 mb-5">
          <div className="pe-xl-4 p-0">
            <Utils.HeadingsGenerator
              heading="The Best Travel Agency"
              subheading="Discover The World With Our Guide"
              highlightWord="World"
            />
            <p className="mb-0 text-justify">
              <span>
                Welcome to SK Happy Tours, where your journey to unforgettable
                adventures begins. As a premier tours and travel company, we are
                dedicated to crafting exceptional travel experiences that cater
                to your unique tastes and preferences.
              </span>
              <br />
              <br />
              <span>
                Whether you're seeking the thrill of exploring exotic
                destinations, the serenity of a luxurious getaway, or the rich
                cultural immersion of guided tours, we have the perfect
                itinerary for you. Embark on a journey with us and discover the
                world in a way you've never imagined.
              </span>
              <br />
              <br />
            </p>
            <div className="d-flex flex-column flex-sm-row flex-xl-column gap-xl-0 gap-md-5 align-items-lg-center align-items-xl-start justify-content-md-start justify-content-sm-between">
              <div>
                <CheckMark
                  title={`${control.about.experience} Years of Experience`}
                />
                <CheckMark
                  title={`${control.about.destinations}+ Tours Destinations`}
                />
              </div>
              <div className="d-flex align-items-center gap-2 mt-3 mt-sm-0 mt-xl-3">
                <IconWrapper className="d-flex justify-content-center align-items-center">
                  <i className="bi bi-telephone-forward color-primary" />
                </IconWrapper>
                <div className="d-flex flex-column font-primary-regular">
                  <p className="mb-0">For Information</p>
                  <h4>+91 {control.contact.phone}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 mb-5 d-none d-sm-block">
          <div className="ps-xl-4 p-0 w-100 h-100">
            <img src={about} className="w-100 h-100 object-fit-cover" />
          </div>
        </div>
      </div>
    </section>
  );
}

const IconWrapper = styled.div`
  width: 25px;
  height: 25px;
`;
