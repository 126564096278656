import { useEffect, useContext } from "react";
import React from "react";
import Utils from "../utils";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import About from "../components/About";
import Header from "../components/Header";
import AbsoluteSlider from "../components/AbsoluteSlider";
import Destination from "../components/Destination";
import SearchBar from "../components/SearchBar";
import PopularTours from "../components/PopularTours";
import Hero from "../components/Hero";
import Achievements from "../components/Achievements";
import Footer from "../components/Footer";
import Live from "../components/Live";
import TopDestination from "../components/TopDestination";
import { ScreenWidthContext } from "../providers/ScreenWidth";
import Packages from "../components/Packages";
import Contact from "../components/Contact";
import WhyChooseUs from "../components/WhyChooseUs";
import LinkBar from "../components/LinkBar";

export default function Home() {
  const width = useContext(ScreenWidthContext);
  return (
    <>
      <LinkBar />
      <section className="position-relative">
        <div className="position-absolute w-100">
          <Header />
        </div>
        <Hero />
        <div
          className={`w-100 ${
            width <= 767 ? "position-relative pt-5" : "position-absolute"
          }`}
          style={{
            top: { width } <= 767 ? "0" : "60%",
          }}
        >
          <SearchBar />
        </div>
      </section>
      <PopularTours />
      <About />
      <Live />
      <WhyChooseUs />
      <Achievements />
      {/* <Contact /> */}
      {/* <AbsoluteSlider /> */}
      <Footer />
    </>
  );
}
