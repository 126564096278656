import React, { useState } from "react";
import Actions from "../toolkit/actions"
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SignUp() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const isAuthenticated = useSelector((state) => state.reducer.auth.isAuthenticated);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("http://localhost:5005/sign_in", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("User signed up:", data);
        dispatch(
          Actions.loginSuccess({ user: { id: data.uid }, token: data.jwt })
        );

      } else {
        throw new Error("Failed to signup");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/admin" />;
  }

  return (
    <section
      className="w-100 d-flex align-items-center justify-content-center"
      style={{
        height: "100dvh",
      }}
    >
      <main
        className="m-auto w-100"
        style={{
          maxWidth: "330px",
          padding: "1rem",
        }}
      >
        <form onSubmit={handleSubmit} className="w-100">
          <div className="form-floating w-100">
            <input
              className="form-control w-100"
              type="email"
              placeholder="Email"
              id="floatingInput"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="floatingInput">Email address</label>
          </div>
          <div className="form-floating mt-2">
            <input
              className="form-control"
              type="password"
              id="floatingPassword"
              placeholder="Password"
              defaultValue={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="floatingPassword">Password</label>
          </div>
          <button className="btn btn-primary w-100 py-2 mt-4" type="submit">
            Sign Up
          </button>
        </form>
      </main>
    </section>
  );
}

export default SignUp;
