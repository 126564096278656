import { useEffect, useContext } from "react";
import React from "react";
import axios from "axios";
import Header from "../components/Header";
import AbsoluteSlider from "../components/AbsoluteSlider";
import Destination from "../components/Destination";
import SearchBar from "../components/SearchBar";
import PopularTours from "../components/PopularTours";
import Hero from "../components/Hero";
import Achievements from "../components/Achievements";
import Footer from "../components/Footer";
import Live from "../components/Live";
import TopDestination from "../components/TopDestination";
import { ScreenWidthContext } from "../providers/ScreenWidth";
function About() {

  const width = useContext(ScreenWidthContext);
  return (
    <>
      <div className="position-relative">
        <div className="position-absolute w-100">
          <Header />
        </div>
        <div>
          <Hero />
        </div>
        <div
          className={`w-100 ${
            width <= 767 ? "position-relative p-5" : "position-absolute"
          }`}
          style={{
            top: { width } <= 767 ? "0" : "70%",
          }}
        >
          <SearchBar />
        </div>
      </div>
      {/* <section className="container py-5">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <p className="mb-0">The Best Travel Agency</p>
            <h1>Discover The World With Our Guide</h1>
            <p>
              You can choose any country with good tourism. Agency elementum
              sesue the aucan vestibulum aliquam justo in sapien rutrum
              volutpat. Donec in quis the pellentesque velit. Donec id velit ac
              arcu posuere blane.
              <br />
              Hotel ut nisl quam nestibulum ac quam nec odio elementum ceisue
              the miss varius natoque penatibus et magnis dis parturient monte.
            </p>
            <div className="d-flex align-items-center">
              20 Years of Experience
            </div>
            <div className="d-flex align-items-center mb-4">
              150+ Tour Destinations
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <p className="mb-0">For information</p>
                <h4>09188085590</h4>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <img src={aboutImage} />
          </div>
        </div>
      </section> */}
      {/* <PopularTours /> */}
      {/* <Destination /> */}
      {/* <Live /> */}
      {/* <Achievements /> */}
      {/* <AbsoluteSlider /> */}
      <TopDestination />
      <Footer />
    </>
  );
}

export default About;
