import hero from "../assets/hero.jpg";
import MouseScroll from "./MouseScroll";
import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { ScreenWidthContext } from "../providers/ScreenWidth";

export default function Hero() {
  const width = useContext(ScreenWidthContext);
  return (
    <Container
      $width={width}
      className="d-flex align-items-center justify-content-center vw-100 py-5 px-md-5 position-relative"
    >
      <div
        className={`container d-flex align-items-center flex-column text-white text-center h-100 py-5 px-lg-5 ${
          width < 768 && "justify-content-center"
        }`}
      >
        <div className="px-lg-5 py-md-5 py-3 px-5">
          <Paragraph className="text-uppercase font-weight-bold">
            Explore Wonders With Us
          </Paragraph>
          <Heading $width={width} className="text-uppercase px-md-5">
            Let'<Span>s</Span> D<Span>is</Span>cover G<Span>e</Span>m
            <Span>s</Span> To<Span>gether</Span>
          </Heading>
        </div>
        <MouseWrapper className="position-absolute">
          <MouseScroll />
        </MouseWrapper>
      </div>
    </Container>
  );
}

const Container = styled.section`
  ${({ $width }) =>
    $width >= 768
      ? css`
          height: calc(100vh - 44px);
        `
      : css`
          height: calc(100vh - 76px);
        `}
  background-color: rgb(30 147 172 / 100%);
  background-image: url("${hero}");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-blend-mode: multiply;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 3px;
`;

const Heading = styled.h1`
  ${({ $width }) =>
    $width <= 785
      ? css`
          font-size: 2.5rem;
        `
      : $width <= 805
      ? css`
          font-size: 3rem;
        `
      : $width <= 1200
      ? css`
          font-size: 3.5rem;
        `
      : css`
          font-size: 5rem;
        `}
  font-weight: 900;
  letter-spacing: 3px;
`;

const Span = styled.span`
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-fill-color: transparent;
`;

const MouseWrapper = styled.div`
  bottom: 50px;
`;
