import React from "react";
import { control } from "../control";
import live from "../assets/live.jpg";
import styled from "styled-components";

export default function Live() {
  return (
    <Container className="text-white vh-50 text-center">
      <Wrapper className="h-100">
        <div className="container d-flex flex-column justify-content-center align-items-center h-100 px-3">
          <Heading className="text-uppercase font-primary-regular letter-spacing-1">
            Discover the magic with SK&nbsp;Happy&nbsp;Tours
          </Heading>
          <div className="d-flex align-items-center gap-4">
            {control.live.map((item, index) => (
              <p key={index} className="d-flex align-items-center gap-2">
                <i className={`bi bi-${item.icon}`} />
                <span>{item.name}</span>
              </p>
            ))}
          </div>
        </div>
      </Wrapper>
    </Container>
  );
}

const Container = styled.section`
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(${live});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  i {
    font-size: 1.5rem;
  }
`;

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
`;

const Heading = styled.h1`
  font-weight: 900;
`;
