import React, { useState } from "react";
import Header from "../components/Header";
import axios from "axios";
import { control } from "../control";
import styled from "styled-components";
import Footer from "../components/Footer";
import LinkBar from "../components/LinkBar";
import bg from "../assets/bg.jpg";

export default function Contact() {
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:5005/send-email", state)
      .then((res) => {
        console.log(res);
        setState({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <>
      <LinkBar />
      <Container>
        <Header />
        <div className="container">
          <div className="row py-5">
            <section className="col-xl-4 col-lg-6 col-md-12">
              <div className="h-100">
                <h1 className="letter-spacing-2 font-primary-regular mb-4 text-white">
                  Let's Get In Touch
                </h1>
                <div className="d-flex flex-lg-column flex-row justify-content-between gap-3 flex-wrap">
                  <div className="d-flex align-items-center gap-4">
                    <i
                      className="d-flex align-items-center justify-content-center bi bi-telephone"
                      style={{
                        fontSize: "18px",
                        backgroundColor: "white",
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    />
                    <h6 className="mb-0 text-white">+91 {control.contact.phone}</h6>
                  </div>
                  <div className="d-flex align-items-center gap-4">
                    <i
                      className="d-flex align-items-center justify-content-center bi bi-envelope"
                      style={{
                        fontSize: "18px",
                        backgroundColor: "white",
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    />
                    <h6 className="mb-0 text-white">skhappytours@gmail.com</h6>
                  </div>
                  <div className="d-flex align-items-center gap-4 mb-4">
                    <i
                      className="d-flex align-items-center justify-content-center bi bi-pin-map"
                      style={{
                        fontSize: "18px",
                        backgroundColor: "white",
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                    />
                    <h6 className="mb-0 text-white">
                      Shimla, Himachal Pradesh
                    </h6>
                  </div>
                </div>
              </div>
            </section>
            <section className="col-xl-8 col-lg-6 col-md-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13666.111657600555!2d77.19635530784751!3d31.09505614058084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39058211d78fdd2f%3A0xa5ceeeeed252c624!2sBhattakufer%2C%20Shimla%2C%20Himachal%20Pradesh!5e0!3m2!1sen!2sin!4v1722057090710!5m2!1sen!2sin"
                width="100%"
                height="450"
                loading="lazy"
              />
            </section>
          </div>
          <div className="row pb-5">
            <section className="col-6">
              <form
                className="d-flex flex-column gap-4 bg-white px-4 py-5"
                onSubmit={sendEmail}
              >
                <div className="d-flex gap-3">
                  <input
                    className="w-50"
                    type="text"
                    value={state.name}
                    placeholder="Name"
                    onChange={(e) => {
                      setState({
                        ...state,
                        name: e.target.value,
                      });
                    }}
                  />
                  <input
                    type="email"
                    className="w-50"
                    placeholder="Email"
                    value={state.email}
                    onChange={(e) => {
                      setState({
                        ...state,
                        email: e.target.value,
                      });
                    }}
                  />
                </div>
                <input
                  type="text"
                  placeholder="Subject"
                  value={state.subject}
                  onChange={(e) => {
                    setState({
                      ...state,
                      subject: e.target.value,
                    });
                  }}
                />
                <textarea
                  placeholder="Message"
                  value={state.message}
                  className="vh-20"
                  style={{
                    resize: "none",
                  }}
                  onChange={(e) => {
                    setState({
                      ...state,
                      message: e.target.value,
                    });
                  }}
                />
                <div className="d-flex align-items-center gap-4 pt-3">
                  <input
                    className="form-control vw-20 p-3"
                    type="button"
                    value="Clear"
                    style={{
                      color: "white",
                      outline: "none",
                      border: "none",
                      backgroundColor: "#006769",
                    }}
                  />
                  <input
                    className="form-control vw-20 p-3"
                    type="submit"
                    value="Send"
                    style={{
                      color: "white",
                      outline: "none",
                      border: "none",
                      backgroundColor: "#006769",
                    }}
                  />
                </div>
              </form>
            </section>
            <section className="col-6"></section>
          </div>
        </div>
        <Footer />
      </Container>
    </>
  );
}

const Container = styled.footer`
  background-color: #003d3ea1;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  input[type="text"],
  input[type="email"],
  textarea {
    border: none;
    outline: none;
    border-bottom: 1px solid #006769;
  }
`;
