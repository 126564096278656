import React from "react";
import Utils from "../utils";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import kullu_img from "../assets/tours/kullu.jpg";
import mandi_img from "../assets/tours/mandi.jpg";
import shimla_img from "../assets/tours/shimla.jpg";
import leh_img from "../assets/tours/leh.jpg";
import kasol_img from "../assets/tours/kasol.jpg"
import spiti_img from "../assets/tours/spiti.jpg"
import kaza_img from "../assets/tours/kaza.jpg"
import kinnaur_img from "../assets/tours/kinnaur.jpg"
import kashmir_img from "../assets/tours/kashmir.jpg"
import dharamshala_img from "../assets/tours/dharamshala.jpg"
export default function PopularTours() {
  const navigate = useNavigate();
  const data = [
    {
      title: "Shimla",
      img: shimla_img,
    },
    {
      title: "Kullu",
      img: kullu_img,
    },
    {
      title: "Mandi",
      img: mandi_img,
    },
    {
      title: "Leh",
      img: leh_img,
    },
    { title: "Kasol", img: kasol_img },
    { title: "Spiti", img: spiti_img },
    { title: "Kaza", img: kaza_img },
    { title: "Kinnaur", img: kinnaur_img },
    { title: "Kashmir", img: kashmir_img },
    { title: "Dharamshala", img: dharamshala_img },
  ];

  return (
    <section className="pt-5">
      <div className="container">
        <Utils.HeadingsGenerator
          heading="Choose Your Place"
          subheading="Popular Tours"
          highlightWord="Tours"
        />
        <div className="row">
          {data.map((item, index) => (
            <div key={index} className="col-xl-4 col-md-6 col-12 mb-4">
              <div
                className="rounded position-relative rounded overflow-hidden cursor-pointer"
                onClick={() => navigate(`/tours/${item.title.toLowerCase()}`)}
              >
                <img src={item.img} className="object-fit-cover w-100 vh-40" />
                <PlaceTitle
                  className="mb-0 position-absolute letter-spacing-2 text-white text-center d-flex justify-content-center align-items-center"
                  style={{
                    inset: "0 0 0 0",
                    margin: "auto",
                  }}
                >
                  {item.title}
                </PlaceTitle>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const PlaceTitle = styled.p`
  font-size: 0px;
  font-weight: 900;
  background-color: rgba(0, 0, 0, 0.4);
  transition: font-size 0.5s ease-in-out;
  &:hover {
    font-size: 2.5rem;
    font-weight: 900;
  }
`;
