import { control } from "../control";
import styled from "styled-components";
import React, { useContext } from "react";
import { ScreenWidthContext } from "../providers/ScreenWidth";

export default function LinkBar() {
  const width = useContext(ScreenWidthContext);

  const email = control.contact.email;
  const state = control.contact.state;
  const phoneNumber = control.contact.phone;
  const district = control.contact.district;
  const instagramUsername = control.contact.instagramUsername;

  const links = [
    {
      title: "Email",
      url: `mailto:${email}`,
      icon: "envelope",
    },
    {
      title: "Phone",
      url: `tel:+91${phoneNumber}`,
      icon: "telephone",
    },
    {
      title: "WhatsApp",
      url: `https://wa.me/91${phoneNumber}`,
      icon: "whatsapp",
    },
    {
      title: "Instagram",
      url: `https://www.instagram.com/${instagramUsername}`,
      icon: "instagram",
    },
  ];

  return (
    <section
      className={`container d-flex py-2 overflow-hidden bg-white align-items-center justify-content-between ${
        width >= 768 ? "flex-row" : "flex-column text-center gap-2"
      }`}
    >
      <div className="d-flex gap-2">
        {links.map((link, index) => (
          <Link key={index} href={link.url} target="_blank" title={link.title}>
            <i className={`bi bi-${link.icon}`} />
          </Link>
        ))}
      </div>
      <div className="d-flex gap-2">
        <p className="d-flex align-items-center mb-0 gap-1">
          <i className="bi bi-telephone-fill text-dark" />
          <a
            href={`tel:+91${phoneNumber}`}
            className="text-dark text-decoration-none"
          >
            +91&nbsp;{phoneNumber}
          </a>
        </p>
        <p className="d-flex align-items-center mb-0 gap-1">
          <i className="bi bi-geo-alt-fill text-dark" />
          <span className="text-dark">
            {district}&nbsp;{width < 400 ? "" : `, ${state}`}
          </span>
        </p>
      </div>
    </section>
  );
}

const Link = styled.a`
  z-index: 2;
  width: 28px;
  height: 28px;
  padding: 10px;
  display: flex;
  color: #000000;
  font-size: 16px;
  background: #fff;
  border-radius: 50%;
  position: relative;
  align-items: center;
  text-decoration: none;
  border: 1px solid #000;
  justify-content: center;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`;
