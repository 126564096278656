import React from "react";
import Utils from "../utils";

export default function TopDestination() {


  return (
    <section className="container vh-100 overflow-hidden">
      {/* <Utils.HeadingsGenerator
        heading="Most Popular"
        subheading="Popular Destination"
      /> */}
      <div className="position-relative w-100 vh-80 p-2">
        <div className="position-absolute start-0 vw-50 z-1">
          <img
            src={Image}
            style={{
              width: "100%",
              height: "500px",
              objectFit: "cover",
            }}
          />
        </div>
        <div
          className="position-absolute start-50 top-50 z-2 vw-50 translate-middle"
          style={{
            backgroundColor: "white",
            border: "1px solid black",
          }}
        >
          <p>TRAVEL CITIES</p>
          <h4>ITALY, EUROPE</h4>
          <p>
            <small>
              You can choose any country with good tourism. Agency elementum
              sesue the aucan vestibulum aliquam justo in sapien rutrum
              volutpat. Donec in quis the pellentesque velit. Donec id velit ac
              arcu posuere blane.
            </small>
          </p>
          <div className="row">
            <div className="col-4">Italy</div>
            <div className="col-4">Italy</div>
            <div className="col-4">Italy</div>
            <div className="col-4">Italy</div>
            <div className="col-4">Italy</div>
            <div className="col-4">Italy</div>
          </div>
          <hr />
          <a href="#">
            <span>View Places</span>
            <i className="bi bi-arrow-right-short"></i>
          </a>
        </div>
      </div>
    </section>
  );
}
