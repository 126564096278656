import Utils from "../utils";
import { control } from "../control";
import Actions from "../toolkit/actions";
import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { ScreenWidthContext } from "../providers/ScreenWidth";

export default function SearchBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useContext(ScreenWidthContext);

  const [state, setState] = React.useState({
    duration: "",
    destination: "",
  });

  const find = () => {
    dispatch(Actions.queryDestination(state.destination));
    dispatch(Actions.queryDuration(state.duration));
    navigate("/tours");
  };

  return (
    <section className="container">
      <Container
        $width={width}
        className={`px-5 ${width < 768 ? "py-5" : "py-4"}`}
      >
        <div
          className={`bg-white ${
            width <= 767 ? "flex-column" : "flex-row row"
          }`}
        >
          {Object.keys(control.searchBar).map((key, index) => (
            <div
              key={index}
              className={`p-0 ${width <= 767 ? "col-12" : "col-4"}`}
            >
              <select
                className="form-select p-3 cursor-pointer"
                onChange={(e) => setState({ ...state, [key]: e.target.value })}
              >
                <option defaultValue={`Select ${Utils.toTitleCase(key)}`}>
                  {`Select ${Utils.toTitleCase(key)}`}
                </option>
                {control.searchBar[key].map((option, idx) => (
                  <option value={option} key={idx}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          ))}
          <div className={`p-0 ${width <= 767 ? "col-12" : "col-4"}`}>
            <Button
              className="p-3 cursor-pointer w-100 font-weight-bold"
              onClick={find}
            >
              <i className="bi bi-search" />
              <span className="ms-2">Find&nbsp;Now</span>
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
}

const Container = styled.div`
  ${({ $width }) =>
    $width < 768
      ? css`
          border: 1px solid #006769;
          box-shadow: none;
          & .form-select {
            border-radius: 0 !important;
            border: 1px solid #006769 !important;
            border-bottom: none !important;
          }
        `
      : css`
          & .form-select {
            border: none !important;
          }
          backdrop-filter: blur(16px);
          background-color: rgba(255, 255, 255, 0.5);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        `}
`;

const Button = styled.button`
  color: white;
  border: none;
  outline: none;
  border-radius: 0;
  background-color: #006769;
`;
