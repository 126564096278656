export const control = {
  contact: {
    phone: "8988422468",
    email: "skhappytours@gmail.com",
    instagramUsername: "skhappytours",
    district: "Shimla",
    state: "Himachal Pradesh",
  },
  header: {
    links: [
      {
        name: "Home",
        url: "/",
      },
      {
        name: "Gallery",
        url: "/gallery",
      },
      {
        name: "Contact",
        url: "/contact",
      },
    ],
  },
  about: {
    experience: 2,
    destinations: 50,
  },
  searchBar: {
    destination: ["Shimla", "Manali", "Leh/Ladakh", "Jammu & Kashmir"],
    duration: ["2-4 Days", "5-7 Days", "7+ Days"],
  },
  live: [
    {
      name: "Manali",
      icon: "geo-alt",
    },
    {
      name: "4 Days + 3 Nights",
      icon: "clock",
    },
  ],
  achievements: [
    {
      name: "Happy Customers",
      value: 200,
      logo: "emoji-smile",
    },
    {
      name: "Tour Destinations",
      value: 50,
      logo: "geo-alt",
    },
    {
      name: "Years of Experience",
      value: 2,
      logo: "calendar",
    },
    {
      name: "Tour Guides",
      value: 5,
      logo: "person",
    },
  ],
  whyChooseUs: [
    {
      icon: "headset",
      color: "#515151",
      cardTitle: <span>24&nbsp;x&nbsp;7 Support</span>,
      cardText:
        "From the moment a reservation is made until our traveler's return home, SK Happy Tours provide 24 x 7 on-trip support.",
    },
    {
      icon: "shield-check",
      color: "#515151",
      cardTitle: <span>Trust&nbsp;&amp;&nbsp;Safety</span>,
      cardText:
        "We are registered with Department of Tourism, Govt. of Himachal . We always give priority to our guests for there safety that build trust.",
    },
    {
      icon: "person-walking",
      color: "#515151",
      cardTitle: <span>Best&nbsp;Agents</span>,
      cardText:
        "More than 2+ years of experience, every year about 5000 travelers booked tour package by us, with client satisfaction rate of 95%.",
    },
    {
      icon: "tags",
      color: "#515151",
      cardTitle: <span>Best&nbsp;Prices</span>,
      cardText:
        "We always offer tour packages with Best Price Guarantee which ensures you to book tour package through SK Happy Tours.",
    },
  ],
};
