export default class Utils {
  static toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  static HeadingsGenerator = ({ heading, subheading, highlightWord }) => {
    heading = heading.toUpperCase();
    return (
      <>
        <p className="mb-0 color-primary font-primary-medium letter-spacing-2">
          {heading}
        </p>
        <h1 className="font-face-2 font-primary-medium mb-4 text-uppercase letter-spacing-2">
          {subheading.split(" ").map((word) => {
            if (word.toLowerCase() === highlightWord.toLowerCase()) {
              return (
                <span className="color-primary" key={word}>
                  {word.toUpperCase() + " "}
                </span>
              );
            }
            return word + " ";
          })}
        </h1>
      </>
    );
  };
}
