import { control } from "../control";
import Logo from "../assets/logo.svg";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { ScreenWidthContext } from "../providers/ScreenWidth";
import React, { useContext, useState, useEffect } from "react";

export default function Header({ bg = "transparent", color = "white" }) {
  const location = useLocation();
  const links = control.header.links;
  const width = useContext(ScreenWidthContext);
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {if (width => 992) setExpanded(false)}, [width]);
  const filterLinks = links.filter((value) => value.url !== location.pathname);

  return (
    <HeaderWrapper $bg={bg} $color={color} className="navbar navbar-expand-lg">
      <nav className="container">
        <Link className="navbar-brand" to={"/"}>
          <img src={Logo} alt="logo" width="50px" />
        </Link>
        {width < 992 && (
          <Toggle
            data-bs-target="#link_bar"
            data-bs-toggle="collapse"
            onClick={() => setExpanded(!expanded)}
            className="d-flex cursor-pointer align-items-center justify-content-center"
          >
            <i className={`bi bi-${expanded ? "x-lg" : "list"}`} />
          </Toggle>
        )}
        <div
          id="link_bar"
          className={`collapse navbar-collapse ${
            width < 992 && "bg-white mt-2"
          }`}
        >
          <ul className={`navbar-nav ms-auto gap-5 ${width < 992 && "p-5"}`}>
            {filterLinks.map((value, index) => (
              <li className="nav-item text-center" key={index}>
                <URL
                  $color={color}
                  $width={width}
                  to={value.url}
                  className="d-block position-relative text-decoration-none d-inline-block"
                >
                  {value.name}
                </URL>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </HeaderWrapper>
  );
}

const HeaderWrapper = styled.header`
  ${({ $bg, $color }) =>
    $bg &&
    css`
      z-index: 1000;
      color: ${$color};
      background: ${$bg};
    `}
`;

const Toggle = styled.div`
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1.8rem;
  border-radius: 50%;
  background-color: transparent;
`;

const URL = styled(Link)`
  ${({ $color, $width }) =>
    $width < 992 && $color === "white"
      ? css`
          color: black;
          &:hover::after {
            background-color: black;
          }
        `
      : css`
          color: ${$color};
          &:hover::after {
            background-color: white;
          }
        `}

  &:hover::after {
    width: 4px;
    content: "";
    height: 4px;
    margin: auto;
    position: absolute;
    border-radius: 50%;
    inset: auto 0 -2px 0;
  }
`;
