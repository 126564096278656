import React from "react";
import styled, { keyframes } from "styled-components";

export default function MouseScroll() {
  const scrollDown = () => {
    window.scrollTo({
      behavior: "smooth",
      top: window.innerHeight,
    });
  };
  return (
    <Scroll>
      <Wrapper onClick={scrollDown}>
        <M>
          <S />
        </M>
      </Wrapper>
    </Scroll>
  );
}

const Scroll = styled.div`
  width: 100%;
  height: 60px;
  z-index: 1001;
  margin-top: 50px;
  position: relative;
`;

const Wrapper = styled.div`
  margin: auto;
  width: 34px;
  height: 55px;
  inset: 0 0 0 0;
  cursor: pointer;
  position: absolute;
`;

const M = styled.div`
  width: 3px;
  height: 35px;
  opacity: 0.75;
  padding: 10px 15px;
  border-radius: 25px;
  box-sizing: content-box;
  border: 2px solid #fff;
`;

const scroll_animation = keyframes`
  0% {
      opacity: 0;
  }

  10% {
      transform: translateY(0);
      opacity: 1;
  }

  100% {
      transform: translateY(15px);
      opacity: 0;
  }
`;

const S = styled.div`
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: ${scroll_animation};
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
`;
