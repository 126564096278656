import React from 'react'

function AddTours() {
  return (
    <section className='py-5'>
        <form>
            <input type="text" placeholder='Tour Name' />
            <input type="text" placeholder='Tour Location' />
            <input type="text" placeholder='Tour Duration' />
            <input type='text' placeholder='Tour Price' />
            <input type='text' placeholder='Tour Max Group Size' />
            <input type='text' placeholder='Tour Difficulty' />
            <input type='text' placeholder='Tour Summary' />
            <input type='text' placeholder='Tour Description' />
            <input type='file' placeholder='Tour Image' />
        </form>
    </section>
  )
}

export default AddTours