import "intersection-observer";
import { control } from "../control";
import hero from "../assets/hero.jpg";
import styled from "styled-components";
import React, { useEffect, useState, useRef } from "react";

const Counter = ({ value }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const duration = 2000;
  const increment = value / (duration / 10);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(counterRef.current);
        }
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) observer.observe(counterRef.current);

    return () => {
      if (counterRef.current) observer.unobserve(counterRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    let currentCount = 0;
    const updateCounter = () => {
      currentCount += increment;
      if (currentCount < value) {
        setCount(Math.ceil(currentCount));
        setTimeout(updateCounter, 10);
      } else setCount(value);
    };

    updateCounter();
  }, [isVisible, value, increment]);

  return (
    <h1 className="text-white" ref={counterRef}>
      {count.toFixed(value % 1 === 0 ? 0 : 1)}+
    </h1>
  );
};

export default function Achievements() {
  return (
    <Container>
      <Wrapper>
        <div className="container">
          <div className="row p-5">
            {control.achievements.map((achievement, idx) => (
              <div
                key={idx}
                className="col-lg-3 col-md-6 col-sm-12 text-center py-lg-5"
              >
                <DataWrapper className="border-rounded p-3">
                  <IconWrapper className="cursor-pointer p-2">
                    <Icon className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <i
                        className={`bi bi-${achievement.logo} fs-1 text-white`}
                      />
                    </Icon>
                  </IconWrapper>
                  <Counter value={achievement.value} />
                  <p className="text-white mb-0">{achievement.name}</p>
                </DataWrapper>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </Container>
  );
}

const Container = styled.section`
  overflow: hidden;
  background-image: url(${hero});
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
`;

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
`;

const DataWrapper = styled.div`
  h1 {
    font-weight: 900;
  }
  p {
    font-weight: 700;
  }
`;

const IconWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  border: 8px solid #ffffff4f;

  & i {
    font-size: 40px !important;
    transition: all 0.3s;
  }
  &:hover i {
    transform: scale(1.1);
    transition: all 0.3s;
  }
`;

const Icon = styled.div`
  background: rgb(255 255 255 / 56%);
  border-radius: 50%;
  backdrop-filter: blur(5px);
`;
