import React from "react";
import axios from "axios";
import LinkBar from "../components/LinkBar";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom";
import Utils from "../utils";
import Footer from "../components/Footer";
import { useContext } from "react";
import { ScreenWidthContext } from "../providers/ScreenWidth";

export default function PlaceList() {
  const { location } = useParams();
  const width = useContext(ScreenWidthContext);

  const tours = {
    kullu: [
      {
        name: "Amazing Ladakh Tour Package",
        nights: 3,
        days: 4,
        highlight: [
          "Leh",
          "Shey",
          "Thiksey",
          "Khardong La",
          "Hall of Fame",
          "Shanti Stupa",
        ],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 10000,
      },
      {
        name: "Kullu-Manali Adventure",
        nights: 4,
        days: 5,
        highlight: ["Rohtang Pass", "Solang Valley", "Hadimba Temple"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 12000,
      },
      {
        name: "Kullu Valley Tour",
        nights: 2,
        days: 3,
        highlight: ["Raghunath Temple", "Bijli Mahadev Temple"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8000,
      },
      {
        name: "Kullu Cultural Tour",
        nights: 3,
        days: 4,
        highlight: ["Great Himalayan National Park", "Manikaran"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9000,
      },
      {
        name: "Kullu Trekking Tour",
        nights: 5,
        days: 6,
        highlight: ["Hampta Pass Trek", "Chandratal Lake"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 15000,
      },
    ],
    shimla: [
      {
        name: "Shimla Summer Festival Tour",
        nights: 3,
        days: 4,
        highlight: ["Mall Road", "Jakhoo Temple", "Summer Festival"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9000,
      },
      {
        name: "Shimla Winter Wonderland",
        nights: 4,
        days: 5,
        highlight: ["Kufri", "Mall Road", "Christ Church"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 11000,
      },
      {
        name: "Shimla Heritage Tour",
        nights: 2,
        days: 3,
        highlight: ["Viceregal Lodge", "Gaiety Theater", "Kali Bari Temple"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8000,
      },
      {
        name: "Shimla Nature Walk",
        nights: 3,
        days: 4,
        highlight: ["Annandale", "Glen", "Chadwick Falls"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8500,
      },
      {
        name: "Shimla Honeymoon Package",
        nights: 5,
        days: 6,
        highlight: ["Mall Road", "Kufri", "Naldehra"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 13000,
      },
    ],
    mandi: [
      {
        name: "Mandi Spiritual Tour",
        nights: 2,
        days: 3,
        highlight: ["Rewalsar Lake", "Bhima Kali Temple", "Sundernagar Lake"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 7000,
      },
      {
        name: "Mandi Nature Tour",
        nights: 3,
        days: 4,
        highlight: ["Prashar Lake", "Barot Valley", "Shikari Devi Temple"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9500,
      },
      {
        name: "Mandi Adventure Tour",
        nights: 4,
        days: 5,
        highlight: ["Joginder Nagar", "Bir Billing", "Dehnasar Lake"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 11000,
      },
      {
        name: "Mandi Cultural Tour",
        nights: 3,
        days: 4,
        highlight: ["Bhutnath Temple", "Tarna Temple", "Suketeswar Temple"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8500,
      },
      {
        name: "Mandi Trekking Tour",
        nights: 5,
        days: 6,
        highlight: ["Parashar Lake Trek", "Shikari Devi Trek"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 14000,
      },
    ],
    leh: [
      {
        name: "Leh Ladakh Tour",
        nights: 3,
        days: 4,
        highlight: [
          "Leh",
          "Shey",
          "Thiksey",
          "Khardong La",
          "Hall of Fame",
          "Shanti Stupa",
        ],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 10000,
      },
      {
        name: "Leh Ladakh Adventure",
        nights: 4,
        days: 5,
        highlight: ["Pangong Lake", "Nubra Valley", "Magnetic Hill"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 12000,
      },
      {
        name: "Leh Ladakh Cultural Tour",
        nights: 2,
        days: 3,
        highlight: ["Leh Palace", "Hemis Monastery", "Diskit Monastery"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8000,
      },
      {
        name: "Leh Ladakh Nature Tour",
        nights: 3,
        days: 4,
        highlight: ["Tso Moriri Lake", "Hemis National Park", "Zanskar Valley"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9000,
      },
      {
        name: "Leh Ladakh Trekking Tour",
        nights: 5,
        days: 6,
        highlight: ["Markha Valley Trek", "Stok Kangri Trek"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 15000,
      },
    ],
    kasol: [
      {
        name: "Kasol Nature Tour",
        nights: 2,
        days: 3,
        highlight: ["Parvati River", "Manikaran", "Tosh"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 7000,
      },
      {
        name: "Kasol Adventure Tour",
        nights: 3,
        days: 4,
        highlight: ["Kheerganga Trek", "Malana Village", "Rasol"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9500,
      },
      {
        name: "Kasol Spiritual Tour",
        nights: 4,
        days: 5,
        highlight: [
          "Manikaran Sahib",
          "Gurudwara Shri Manikaran Sahib",
          "Kasol Temple",
        ],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 11000,
      },
    ],
    spiti: [
      {
        name: "Spiti Valley Tour",
        nights: 3,
        days: 4,
        highlight: ["Kaza", "Key Monastery", "Kibber", "Chandratal Lake"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 10000,
      },
      {
        name: "Spiti Valley Adventure",
        nights: 4,
        days: 5,
        highlight: ["Tabo", "Dhankar Monastery", "Pin Valley National Park"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 12000,
      },
      {
        name: "Spiti Valley Cultural Tour",
        nights: 2,
        days: 3,
        highlight: ["Komic", "Langza", "Kibber", "Kaza"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8000,
      },
      {
        name: "Spiti Valley Nature Tour",
        nights: 3,
        days: 4,
        highlight: ["Kunzum Pass", "Chandratal Lake", "Kaza"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9000,
      },
      {
        name: "Spiti Valley Trekking Tour",
        nights: 5,
        days: 6,
        highlight: ["Pin Parvati Pass Trek", "Chandratal Lake"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 15000,
      },
    ],
    kaza: [
      {
        name: "Kaza Nature Tour",
        nights: 2,
        days: 3,
        highlight: ["Kaza", "Key Monastery", "Kibber", "Chandratal Lake"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 7000,
      },
      {
        name: "Kaza Adventure Tour",
        nights: 3,
        days: 4,
        highlight: ["Tabo", "Dhankar Monastery", "Pin Valley National Park"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9500,
      },
      {
        name: "Kaza Cultural Tour",
        nights: 4,
        days: 5,
        highlight: ["Komic", "Langza", "Kibber", "Kaza"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 11000,
      },
    ],
    kinnaur: [
      {
        name: "Kinnaur Valley Tour",
        nights: 3,
        days: 4,
        highlight: ["Kalpa", "Sangla", "Chitkul", "Reckong Peo"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 10000,
      },
      {
        name: "Kinnaur Valley Adventure",
        nights: 4,
        days: 5,
        highlight: ["Kamru Fort", "Nako Lake", "Roghi Village"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 12000,
      },
      {
        name: "Kinnaur Valley Cultural Tour",
        nights: 2,
        days: 3,
        highlight: ["Reckong Peo", "Kalpa", "Kothi"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8000,
      },
      {
        name: "Kinnaur Valley Nature Tour",
        nights: 3,
        days: 4,
        highlight: ["Chitkul", "Sangla", "Kalpa"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9000,
      },
      {
        name: "Kinnaur Valley Trekking Tour",
        nights: 5,
        days: 6,
        highlight: ["Kinner Kailash Trek", "Chitkul", "Kalpa"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 15000,
      },
    ],
    kashmir: [
      {
        name: "Kashmir Valley Tour",
        nights: 3,
        days: 4,
        highlight: ["Srinagar", "Gulmarg", "Pahalgam", "Sonmarg"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 10000,
      },
      {
        name: "Kashmir Valley Adventure",
        nights: 4,
        days: 5,
        highlight: ["Betaab Valley", "Aru Valley", "Chandanwari"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 12000,
      },
      {
        name: "Kashmir Valley Cultural Tour",
        nights: 2,
        days: 3,
        highlight: ["Shalimar Bagh", "Nishat Bagh", "Chashme Shahi"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8000,
      },
      {
        name: "Kashmir Valley Nature Tour",
        nights: 3,
        days: 4,
        highlight: ["Dal Lake", "Shankaracharya Temple", "Mughal Gardens"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9000,
      },
      {
        name: "Kashmir Valley Trekking Tour",
        nights: 5,
        days: 6,
        highlight: ["Great Lakes Trek", "Tarsar Marsar Trek"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 15000,
      },
    ],
    dharamshala: [
      {
        name: "Dharamshala Tour",
        nights: 3,
        days: 4,
        highlight: ["McLeod Ganj", "Bhagsu Nag", "Dalai Lama Temple"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 10000,
      },
      {
        name: "Dharamshala Adventure",
        nights: 4,
        days: 5,
        highlight: ["Triund Trek", "Bhagsu Nag Waterfall", "Naddi Village"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 12000,
      },
      {
        name: "Dharamshala Cultural Tour",
        nights: 2,
        days: 3,
        highlight: ["Namgyal Monastery", "Tibet Museum", "St. John Church"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 8000,
      },
      {
        name: "Dharamshala Nature Tour",
        nights: 3,
        days: 4,
        highlight: ["Dal Lake", "Naddi Village", "Tibetan Market"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 9000,
      },
      {
        name: "Dharamshala Trekking Tour",
        nights: 5,
        days: 6,
        highlight: ["Kareri Lake Trek", "Triund Trek", "Indrahar Pass Trek"],
        includes: ["meals", "transport", "sightseeing", "hotel"],
        price: 15000,
      },
    ],
  };

  // const [formState, setFormState] = React.useState({
  //   name: "",
  //   nights: 0,
  //   days: 1,
  //   highlight: [],
  //   includes: {
  //     meals: false,
  //     transport: false,
  //     sightseeing: false,
  //   },
  //   price: "",
  //   img: "",
  //   destination: "",
  // });

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(formState);
  // };
  return (
    <>
      <LinkBar />
      <Header bg="black" color="white" />

      {/* <form
        className="container py-5 d-flex flex-column gap-1"
        onSubmit={handleSubmit}
      >
        <input
          type="text"
          placeholder="Tour Name"
          onChange={(e) => setFormState({ ...formState, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Destination"
          onChange={(e) =>
            setFormState({ ...formState, destination: e.target.value })
          }
        />
        <textarea
          onChange={(e) => {
            setFormState({
              ...formState,
              highlight: e.target.value.split(","),
            });
          }}
        ></textarea>
        <input
          type="number"
          placeholder="Nights"
          value={formState.nights}
          onChange={(e) =>
            setFormState({ ...formState, nights: e.target.value })
          }
        />
        <input
          type="number"
          placeholder="Days"
          value={formState.days}
          onChange={(e) => setFormState({ ...formState, days: e.target.value })}
        />
        <div className="d-flex gap-4">
          <div className="d-flex gap-2">
            <label htmlFor="meals">Meals</label>
            <input
              type="checkbox"
              id="meals"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  includes: { ...formState.includes, meals: e.target.checked },
                })
              }
              checked={formState.includes.meals}
            />
          </div>
          <div className="d-flex gap-2">
            <label htmlFor="transport">Transport</label>
            <input
              type="checkbox"
              id="transport"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  includes: {
                    ...formState.includes,
                    transport: e.target.checked,
                  },
                })
              }
            />
          </div>
          <div className="d-flex gap-2">
            <label htmlFor="sightseeing">Sightseeing</label>
            <input
              type="checkbox"
              id="sightseeing"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  includes: {
                    ...formState.includes,
                    sightseeing: e.target.checked,
                  },
                })
              }
            />
          </div>
        </div>
        <input
          type="file"
          max={1}
          placeholder="Upload Image"
          onChange={(e) =>
            setFormState({ ...formState, img: e.target.files[0] })
          }
        />
        <input
          type="text"
          placeholder="Price"
          value={formState.price}
          onChange={(e) =>
            setFormState({ ...formState, price: e.target.value })
          }
        />
        <input type="submit" value="Add Tour" />
      </form> */}

      <nav aria-label="breadcrumb" className="container py-3">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {Utils.toTitleCase(location)}
          </li>
        </ol>
      </nav>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-12">
            {tours[location].map((item, index) => (
              <div key={index} className="mb-4 font-primary-regular">
                <div className="card flex-column flex-lg-row">
                  <div className="d-flex flex-column">
                    <img
                      src="https://himalayanholidayers.com/uploads/destination/ladakh.jpg"
                      alt=""
                      className={`h-100 ${width > 991 ? "vw-25" : "w-100"}`}
                    />
                    <span className="text-center bp p-2 text-white">
                      {item.name}
                    </span>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title color-primary">{item.name}</h5>
                    <p className="card-text">
                      <strong>
                        {item.nights} Nights / {item.days} Days
                      </strong>
                      <hr />
                      <strong>Highlights</strong>
                      <div>{item.highlight.join(", ")}</div>
                      <hr />
                      <strong>Includes:</strong> {item.includes.join(", ")}
                    </p>
                    <div className="d-flex align-items-center justify-content-start">
                      <span>₹{item.price}/- Onwards</span>
                      {/* <a href="#" className="btn btn-primary">
                        Book Now
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
