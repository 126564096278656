import "./index.css";
import React from "react";
import Routes from "./routes";
import { Provider } from "react-redux";
import { store } from "./toolkit/toolkit";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ScreenWidthProvider } from "./providers/ScreenWidth";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <ScreenWidthProvider>
        <Routes />
      </ScreenWidthProvider>
    </Provider>
  </React.StrictMode>
);
