import React from "react";
import { control } from "../control";
import styled from "styled-components";

export default function WhyChooseUs() {
  return (
    <section className="container py-5 text-center">
      <h1 className="text-uppercase letter-spacing-2 font-primary-regular mb-4">
        Why Choose Us
      </h1>
      <div className="row h-100">
        {control.whyChooseUs.map((item, index) => (
          <div key={index} className="col-xl-3 col-md-6 col-12 p-2 h-100">
            <Wrapper $color={item.color} className="p-3">
              <i className={`bi bi-${item.icon}`} />
              <h3 className="font-primary-regular">{item.cardTitle}</h3>
              <p className="font-primary-regular">{item.cardText}</p>
            </Wrapper>
          </div>
        ))}
      </div>
    </section>
  );
}

const Wrapper = styled.div`
  ${({ $color }) => `
    color: ${$color};
    border: 2px solid ${$color};
  `}
  i {
    font-size: 4rem;
  }
`;
