import Types from "./types";

export default class Actions {
  static resetStore = () => {
    return {
      type: Types.RESET_STORE,
    };
  };
  
  static loginSuccess = (userData) => ({
    type: Types.LOGIN_SUCCESS,
    payload: userData,
  });

  static logout = () => ({
    type: Types.LOGOUT,
  });

  static queryDestination = (destination) => ({
    type: Types.QUERY_DESTINATION,
    payload: destination,
  });

  static queryDuration = (duration) => ({
    type: Types.QUERY_DURATION,
    payload: duration,
  });
}
