import React from "react";
import Header from "../components/Header";
import LinkBar from "../components/LinkBar";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function Gallery() {
  const navigate = useNavigate();
  const cardsData = [
    {
      title: "Shimla",
      img: "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Fshimla_1.jpg?alt=media&token=9e7f1a4d-28f8-4482-8cd8-56c9f4c2d238",
    },
    {
      title: "Kullu",
      img: "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Fmanali_20.jpg?alt=media&token=392045dc-48be-47e9-8a30-abaae97ae9f1",
    },
    {
      title: "Mandi",
      img: "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Fmandi_1.jpg?alt=media&token=3a851b69-c0b4-4e54-a99c-4489a8a17e47",
    },
  ];

  return (
    <div className="position-relative w-100">
      <LinkBar />
      <Header bg="#006769" />
      <div className="container py-5">
        <div className="row">
          {cardsData.map((item, index) => (
            <Card
              className="col-12 col-md-6 mb-4 position-relative cursor-pointer"
              key={index}
              onClick={() => navigate(`/${item.title.toLowerCase()}`)}
            >
              <img
                src={item.img}
                className="card-img-top vh-50"
                style={{ objectFit: "cover" }}
              />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  fontWeight: "800",
                  position: "absolute",
                  inset: "0 0 0 0",
                  margin: "auto",
                }}
              >
                <Span>{item.title}</Span>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

const Card = styled.div`
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 0px;
      transition: all 0.5s;
    }
  }
  &:hover div {
    span {
      font-size: 4rem;
      transform: scale(1.5);
    }
  }
`;

const Span = styled.span`
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-color: #ffffff;
  -webkit-text-fill-color: transparent;
`;
