import React, { createContext, useState, useEffect } from "react";

export const ScreenWidthContext = createContext();

export const ScreenWidthProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <ScreenWidthContext.Provider value={width}>
      {children}
    </ScreenWidthContext.Provider>
  );
};
