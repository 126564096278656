import React from 'react';
import { Link, useParams } from 'react-router-dom';

const PlaceList = () => {
  const { location } = useParams();

  const cardsData = {
    kullu: [
      {
        title: "Rohtang Pass",
        url: "/kullu/rohtang-pass",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Solang Valley",
        url: "/kullu/solang-valley",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Hadimba Temple",
        url: "/kullu/hadimba-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Mall Road",
        url: "/kullu/mall-road",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Old Manali",
        url: "/kullu/old-manali",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Manu Temple",
        url: "/kullu/manu-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
    ],
    shimla: [
      {
        title: "The Ridge",
        url: "/shimla/the-ridge",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Mall Road",
        url: "/shimla/mall-road",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Jakhoo Temple",
        url: "/shimla/jakhoo-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Kufri",
        url: "/shimla/kufri",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Chail",
        url: "/shimla/chail",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Tara Devi Temple",
        url: "/shimla/tara-devi-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
    ],
    mandi: [
      {
        title: "Rewalsar Lake",
        url: "/mandi/rewalsar-lake",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Prashar Lake",
        url: "/mandi/prashar-lake",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Bhutnath Temple",
        url: "/mandi/bhutnath-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Pandoh Dam",
        url: "/mandi/pandoh-dam",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Barot",
        url: "/mandi/barot",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Shikari Devi Temple",
        url: "/mandi/shikari-devi-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
    ],
  };

  return (
    <div className="container py-5">
      <h2>{location.charAt(0).toUpperCase() + location.slice(1)}</h2>
      <ul className="list-group">
        {cardsData[location].map((place, index) => (
          <li key={index} className="list-group-item">
            <Link to={`/${location}/${place.url.split('/').pop()}`}>{place.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PlaceList;