import React from "react";
import { useSelector } from "react-redux";
// import pdf from "../assets/tours.pdf";

const shimlaTours = [
  {
    name: "Shimla Tour 1",
    duration: "2-4 Days",
    price: 10000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
  {
    name: "Shimla Tour 2",
    duration: "5-7 Days",
    price: 20000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
  {
    name: "Shimla Tour 3",
    duration: "7+ Days",
    price: 30000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
];

const manaliTours = [
  {
    name: "Manali Tour 1",
    duration: "2-4 Days",
    price: 10000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
  {
    name: "Manali Tour 2",
    duration: "5-7 Days",
    price: 20000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
  {
    name: "Manali Tour 3",
    duration: "7+ Days",
    price: 30000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
];

const lehTours = [
  {
    name: "Leh Tour 1",
    duration: "2-4 Days",
    price: 10000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
  {
    name: "Leh Tour 2",
    duration: "5-7 Days",
    price: 20000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
  {
    name: "Leh Tour 3",
    duration: "7+ Days",
    price: 30000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
];

const jkTours = [
  {
    name: "J & K Tour 1",
    duration: "2-4 Days",
    price: 10000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
  {
    name: "J & K Tour 2",
    duration: "5-7 Days",
    price: 20000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
  {
    name: "J & K Tour 3",
    duration: "7+ Days",
    price: 30000,
    images: [
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
      "https://firebasestorage.googleapis.com/v0/b/toursfortoday-v1.appspot.com/o/assets%2Fextra%2Flive.jpg?alt=media&token=37982978-0ec7-42a0-86ea-8601411215d1",
    ],
  },
];

const renderTours = (destination, duration) => {
  switch (destination) {
    case "Shimla":
      return shimlaTours.filter((tour) => tour.duration === duration);
    case "Manali":
      return manaliTours.filter((tour) => tour.duration === duration);
    case "Leh/Ladakh":
      return lehTours.filter((tour) => tour.duration === duration);
    case "Jammu & Kashmir":
      return jkTours.filter((tour) => tour.duration === duration);
    default:
      return [];
  }
};

export default function Tours() {
  const destination = useSelector((state) => state.reducer.queryDestination);
  const duration = useSelector((state) => state.reducer.queryDuration);

  return (
    <div>
      {destination && duration ? (
        <div className="container py-5">
          {renderTours(destination, duration).map((tour, index) => {
            return (
              <div key={index}>
                <div class="card text-center">
                  <div class="card-header">{tour.name}</div>
                  <div class="card-body">
                    <p class="card-text">
                      <div id="carouselExample" class="carousel slide">
                        <div class="carousel-inner">
                          {tour.images.map((image, index) => {
                            return (
                              <div
                                class={`carousel-item ${
                                  index === 0 ? "active" : ""
                                }`}
                              >
                                <img
                                  src={image}
                                  class="d-block w-100 vh-40"
                                  style={{
                                    objectFit: "cover",
                                    objectPosition: "center",
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                        <button
                          class="carousel-control-prev"
                          type="button"
                          data-bs-target="#carouselExample"
                          data-bs-slide="prev"
                        >
                          <span
                            class="carousel-control-prev-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Previous</span>
                        </button>
                        <button
                          class="carousel-control-next"
                          type="button"
                          data-bs-target="#carouselExample"
                          data-bs-slide="next"
                        >
                          <span
                            class="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Next</span>
                        </button>
                      </div>
                    </p>
                    <div className="d-flex align-items-center gap-3 justify-content-center">
                      <a href="#" class="btn btn-primary">
                        Book
                      </a>
                      {/* <a
                        href={pdf}
                        class="btn btn-primary"
                        download="tours.pdf"
                      >
                        View
                      </a> */}
                    </div>
                  </div>
                  <div class="card-footer text-body-secondary">
                    {tour.duration}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <h1>No tours found</h1>
      )}
    </div>
  );
}
