import React, { useState } from "react";
import { useParams } from "react-router-dom";

const PopUp = ({ img, onClose }) => {


  return (
    // <div className="modal show d-block" tabIndex="-1" role="dialog">
    //   <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <button type="button" className="close" onClick={onClose}>
    //           <span>&times;</span>
    //         </button>
    //       </div>
    //       <div className="modal-body">
    //         <img src={img} className="img-fluid" alt="Popup" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body p-0">
              <img src={img} className="img-fluid" alt="Popup" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function ImageGallery() {
  const { location, place } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);

  const cardsData = {
    kullu: [
      {
        title: "Rohtang Pass",
        url: "/kullu/rohtang-pass",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Solang Valley",
        url: "/kullu/solang-valley",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Hadimba Temple",
        url: "/kullu/hadimba-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Mall Road",
        url: "/kullu/mall-road",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Old Manali",
        url: "/kullu/old-manali",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Manu Temple",
        url: "/kullu/manu-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
    ],
    shimla: [
      {
        title: "The Ridge",
        url: "/shimla/the-ridge",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Mall Road",
        url: "/shimla/mall-road",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Jakhoo Temple",
        url: "/shimla/jakhoo-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Kufri",
        url: "/shimla/kufri",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Chail",
        url: "/shimla/chail",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Tara Devi Temple",
        url: "/shimla/tara-devi-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
    ],
    mandi: [
      {
        title: "Rewalsar Lake",
        url: "/mandi/rewalsar-lake",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Prashar Lake",
        url: "/mandi/prashar-lake",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Bhutnath Temple",
        url: "/mandi/bhutnath-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Pandoh Dam",
        url: "/mandi/pandoh-dam",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Barot",
        url: "/mandi/barot",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
      {
        title: "Shikari Devi Temple",
        url: "/mandi/shikari-devi-temple",
        images: [
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
          "https://c1.wallpaperflare.com/preview/796/443/788/manali-himalayas-quiet-backdrop.jpg",
        ],
      },
    ],
  };

  const placeData = cardsData[location]?.find(
    (_place) => _place.url === `/${location}/${place}`
  );

  if (!placeData) return <h1>Place not found</h1>;

  return (
    <div className="container py-5">
      <h2>{placeData.title}</h2>
      <div className="row">
        {placeData.images.map((img, index) => (
          <div key={index} className="col-12 col-md-4">
            <img
              src={img}
              className="img-thumbnail"
              alt={`Thumbnail ${index + 1}`}
              onClick={() => setSelectedImage(img)}
              style={{ cursor: "pointer" }}
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            />
          </div>
        ))}
      </div>

      {selectedImage && (
        <PopUp img={selectedImage} onClose={() => setSelectedImage(null)} />
      )}
    </div>
  );
}
