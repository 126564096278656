import Types from "./types";
import { combineReducers } from "redux";

const storedUser = JSON.parse(localStorage.getItem("user"));

const reduxState = {
  formSelectedDuration: null,
  formSelectedDestination: null,
  queryDestination: null,
  queryDuration: null,
  auth: storedUser
    ? {
        isAuthenticated: true,
        user: storedUser.user,
        token: storedUser.token,
      }
    : {
        isAuthenticated: false,
        user: null,
        token: null,
      },
};

const reducer = (state = reduxState, action) => {
  switch (action.type) {
    case Types.LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        auth: {
          isAuthenticated: true,
          user: action.payload.user,
          token: action.payload.token,
        },
      };
    case Types.QUERY_DESTINATION:
      return {
        ...state,
        queryDestination: action.payload,
      };
    case Types.QUERY_DURATION:
      return {
        ...state,
        queryDuration: action.payload,
      };
    case Types.LOGOUT:
      localStorage.removeItem("user");
      return {
        ...state,
        auth: {
          isAuthenticated: false,
          user: null,
          token: null,
        },
      };
    default:
      return state;
  }
};

const reducers = combineReducers({ reducer });

export default function rootReducer(state, action) {
  if (action.type === Types.RESET_STORE) state = undefined;
  return reducers(state, action);
}
