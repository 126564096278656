import React from "react";
// import Image from "../assets/canada.jpeg";
import styled from "styled-components";

export default function Destination() {
  const destinations = [
    {
      id: 1,
      name: "Canada",
      image: Image,
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    },
    {
      id: 2,
      name: "USA",
      image: Image,
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    },
    {
      id: 3,
      name: "UK",
      image: Image,
      description:
        "This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.",
    },
  ];
  return (
    <section className="container pt-5">
      <p className="mb-0">Most Popular</p>
      <h1 className="mb-4">
        Popular <span className="text-primary">Destination</span>
      </h1>
      <div className="row justify-content-center">
        {destinations.map((destination, index) => {
          return (
            <Col key={destination.id} className="col-lg-4 col-md-6 col-sm-12">
              <Card className="d-flex justify-content-center">
                <div className="z-3 position-absolute top-0 end-0 p-2">
                  {destination.id}
                </div>
                <img
                  src={destination.image}
                  className="card-img position-absolute bottom-0 z-2"
                />
                <h1 className="z-3 position-absolute mb-0">
                  {destination.name}
                </h1>
              </Card>
            </Col>
          );
        })}
      </div>
    </section>
  );
}

const Card = styled.div`
  height: 450px;
  overflow: hidden;
  position: relative;
  border: none;
  border-radius: 0;
  & img {
    height: 100%;
    width: auto;
    object-fit: cover;
  }
`;

const Col = styled.div`
  margin-bottom: 48px;
  &:nth-child(1) > div {
    margin-right: 30px;
  }
  &:nth-child(2) > div {
    margin-right: 15px;
    margin-left: 15px;
  }
  &:nth-child(3) > div {
    margin-left: 30px;
  }
`;