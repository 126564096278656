import React, { useContext } from "react";
import styled, { css } from "styled-components";
import img_1 from "../assets/absoluteslider/1.png"
import img_2 from "../assets/absoluteslider/2.png"
import img_3 from "../assets/absoluteslider/3.png"
import img_4 from "../assets/absoluteslider/4.png"
import { ScreenWidthContext } from "../providers/ScreenWidth";

export default function AbsoluteSlider() {
  const width = useContext(ScreenWidthContext);

  return (
    <SliderSection $width={width}>
      <PhotoBanner>
        {Array.from({ length: 3 }).map((_, repIdx) =>
          [img_1, img_2, img_3, img_4].map((image, idx) => (
            <Img
              src={image}
              alt="banner"
              $width={width}
              key={`${repIdx}-${idx}`}
            />
          ))
        )}
      </PhotoBanner>
    </SliderSection>
  );
}

const SliderSection = styled.section`
  ${({ $width }) =>
    $width <= 767
      ? css`
          height: 100px;
        `
      : css`
          height: 150px;
        `}
  overflow: hidden;
  position: relative;
`;

const PhotoBanner = styled.figure`
  top: 0px;
  left: 0px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  animation: banner_move 20s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
  @keyframes banner_move {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50%, 0);
    }
  }
`;

const Img = styled.img`
  ${({ $width }) =>
    $width <= 767
      ? css`
          height: 100px;
        `
      : css`
          height: 150px;
        `}

  width: auto;
`;
