import React from "react";
import { control } from "../control";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const email = control.contact.email;
  const phoneNumber = control.contact.phone;
  const username = control.contact.instagramUsername;
  const filterLinks = control.header.links.filter((value) => value.url !== location.pathname);

  const links = [
    {
      title: "Email",
      url: `mailto:${email}`,
      icon: "envelope",
    },
    {
      title: "Phone",
      url: `tel:+91${phoneNumber}`,
      icon: "telephone",
    },
    {
      title: "WhatsApp",
      url: `https://wa.me/91${phoneNumber}`,
      icon: "whatsapp",
    },
    {
      title: "Instagram",
      url: `https://www.instagram.com/${username}`,
      icon: "instagram",
    },
    {
      title: "Facebook",
      url: `https://www.instagram.com/${username}`,
      icon: "facebook",
    },
  ];

  return (
    <FooterWrapper>
      <div className="bg-dark">
        <div className="container d-flex flex-column py-5 justify-content-center align-items-center">
          <div className="d-flex gap-2 mb-4">
            {links.map((link, index) => (
              <URL
                key={index}
                href={link.url}
                target="_blank"
                title={link.title}
              >
                <i className={`bi bi-${link.icon}`} />
              </URL>
            ))}
          </div>
          <div className="d-flex gap-4">
            {filterLinks.map((value, index) => (
              <div className="nav-item text-center" key={index}>
                <Link
                  to={value.url}
                  className="text-decoration-none d-inline-block text-white"
                >
                  {value.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center py-4 text-white bg-dark border-top">
        <p className="mb-0">
          All&nbsp;rights&nbsp;reserved with SK&nbsp;Happy&nbsp;Tours&nbsp;
        </p>
        <p className="mb-0">&copy;&nbsp;2023 - {new Date().getFullYear()}</p>
      </div>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  color: white;
  background-color: #003d3e;
`;

const URL = styled.a`
  z-index: 2;
  width: 35px;
  height: 35px;
  padding: 10px;
  display: flex;
  color: #000000;
  font-size: 16px;
  background: #fff;
  border-radius: 50%;
  position: relative;
  align-items: center;
  text-decoration: none;
  border: 1px solid #000;
  justify-content: center;
  transition: 0.3s;
  &:hover {
    transform: scale(1.1);
  }
`;
